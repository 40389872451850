function Home() {
  return (
    <>
      <h1>This is my Hacking Blog. There are many like it, but this one is mine!</h1>
      <p>This blog is currently under construction. (always will be)</p>
      <p>The content of my blog will primarily be HackTheBox and various CTF write-ups, as well as cool Cybersecurity and Linux findings.</p>
      <p>
        Currently there are some write-ups on various HackTheBox boxes that you can check out! <br />
        Guides that I wrote on various topics. <br />
        Some of my hacking related projects/tool. <br />
        More to come!
      </p>
      <h2>Friends with cool websites:</h2>
      <ul style={{'color': '#ff79c6', 'borderLeft': '2px solid #bd93f9'}}>
        <li><u><a href='https://openpunk.com/'>openpunk</a></u></li>
      </ul>
      <img src='/miami_vice_car_lean.jpg'></img>
    </>

  );
}

export default Home;
