import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/prism'
import { Divider } from "@mui/material"


export default function HackTheBoxIntro() {

  return (
    <div className='writeup-div'>
      <p>
        HackTheBox is one of the greatest platforms to learn hacking from. However, as time passed and the popularity of the website grew, once a simple website, became complicated.<br />
        Back in the day, HackTheBox only offered few services such as the boxes and CTF-like challenges. This is not the case today. In this guide, I will go over the "labs" component of HackTheBox.
      </p>

      <Divider />

      <h2>Registration</h2>
      <p>
        The first step is to register to the website. Navigating to <a href='https://www.hackthebox.com/'>HackTheBox</a>, we are greeted with main page.
        On the top right, you will see a registration section.
      </p>

      <br />
      <img src='/guides/hacktheboxintro/register_options.png'></img>

      <p>
        As you can see, there are two main buttons. The <b>Get Started</b> button is for registering and <b>Sign in</b>, well, for signing in! <br />
        However, there are 4 options, and unfortunately, HackTheBox does not offer global accounts. So, you will have to register to all services if you want to use them. <br />
        In this guide, we will focus on <b>HTB Labs</b>. HTB Labs is where you will have access to free and paid vulnerable machines where you can test your hacking skills and improve them, solve CTF-like challenges and even compete in seasonal hacking competitions.
      </p>

      <br />
      <img src='/guides/hacktheboxintro/labs_registration.png'></img>

      <p>
        This is what the registration page looks like. <br />
        <i>Fun fact:</i> Before the major update of the website, you actually needed to solve a basic challenge to get access to the registration screen. Think it as hacking your way into the website.
      </p>

      <p>
        After you login, you will be greeted with the homepage.
      </p>

      <br />
      <img src='/guides/hacktheboxintro/hackthebox_homepage.png'></img>

      <p>
        If you look at the left pane, you will see different categories. <br />
        The main 4 categories you should consider as a beginner, are:
      </p>
      <ul>
        <li>Starting Point: Very beginner friendly machines with available write-ups (solution guides)</li>
        <li>Machines: Vulnerable machines/boxes with varying difficulties (more on this later)</li>
        <li>Challenges: CTF-like challenges spanning multiple categories</li>
        <li>Tracks: Combination of boxes and challenges on a specific cybersecurity topic to deepen your understanding of that topic</li>
      </ul>

      <Divider />

      <h2>Machines</h2>
      <p>
        Machines are basically vulnerable servers. Your goal is, given an IP address, compromise the server and become the root/administrator user. <br />
        Machines can be Linux, Windows, FreeBSD, OpenBSD and even Android. There can be multiple servers connected with each other. One public facing and the other private/internal or an Active Directory environment. Regardless of the structure, the end goal is the same. Become the root. <br />
        Most of the time the exploitation steps are as follows:
      </p>
      <ol>
        <li>Machine has a vulnerable public facing web server</li>
        <li>You exploit the vulnerability and gain a low privileged user on the server</li>
        <li>Submit the user flag (half way checkpoint)</li>
        <li>Enumerate the server from within to discover more vulnerabilities and secrets</li>
        <li>Using the knowledge gathered, exploit further and become the root user</li>
      </ol>

      <p>
        Throughout the box exploitation, you will get 2 flags. The user and the root. User flag is like a half-way checkpoint and is usually acquired when you first get access to the server. If not, you must pivot to another user in the system. <br />
        The second flag is the root flag. This flag marks the completion of the box. If you got this flag, you have successfully compromised the machine.
      </p>

      <Divider />
      
      <h2>Accessing a Machine</h2>
      <b>Important: Before you start hacking, make sure you are using a Virtual Machine.</b>
      <p>These steps are taken from your <b>Virtual Machine</b>.</p>
      <p>
        To access any machine in Machines category, you will need an <code>OpenVPN</code> connection. There are other methods, but <code>OpenVPN</code> is free and stable. <br />
        On the top right, you will see a red "CONNECT TO HTB" button. When you click it, you will be presented with the following screen:
      </p>

      <br />
      <img src='/guides/hacktheboxintro/connections.png'></img>

      <p>
        Depending on what you want to do, you need to download the correct <code>OpenVPN</code> file. In our case, we will press the <b>Machines</b> button. <br />
        After that, we select the <code>OpenVPN</code> and any server where you get low ping. Finally TCP or UDP protocol. I personally use TCP. <br />
        Now, you should have the <code>lab_USERNAME.ovpn</code> file!
      </p>

      <p>To connect to the HackTheBox VPN, open a new terminal or a tab in your current terminal and run the following command:</p>

      <SyntaxHighlighter language='bash' style={dracula}>
        {`# In the directory of the .ovpn file
sudo openvpn lab_<username>.ovpn`}
      </SyntaxHighlighter>

      <p>
        Finally, you can navigate to the Machines tab, select a machine and press <b>Join Machine</b>. <br />
        After joining the machine, you should see an IP address. Using that IP address, you can start your attacks.
      </p>

      <p>Some final tips on trouble shooting:</p>

      <SyntaxHighlighter language='bash' style={dracula}>
        {`# To check if you have can reach the machine
ping <machine_ip_here>

# Sometimes machines block ICMP, so you can try nmap
nmap -sC -sV -v <machine_ip_here>

# Finally, don't forget to check your interfaces. You should see the OpenVPN interface which happens to be tun0
ip --color=auto addr
`}
      </SyntaxHighlighter>

    <p>Here are some of my <a href='https://armanhz.com/htb'>Write-ups</a> to give you an idea on what to expect when solving a box.</p>
    <p>Thank you for reading.</p>

    </div>
  )
}