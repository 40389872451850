import { Divider } from '@mui/material'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/prism'

export default function BashIntro() {

  return (
    <div className='writeup-div'>
      <h2>Intro</h2>
      <p>In this guide, I will be going over the following subjects:</p>
      <ol>
        <li><a href='#aliases'>Aliases and how to create them</a></li>
        <li><a href='#functions'>Functions, parameters and some bash specific syntax</a></li>
        <li><a href='#persistency'>Saving everything in a file and making them persistent</a></li>
      </ol>

      <Divider />
      
      <h2 id='aliases'>Aliases</h2>
      <p>Aliases, as the name suggests, are aliases for other commands! By using aliases you give any built-in (or custom) bash command whatever name you want. The use case for aliases are not only giving different commands different names. You can also set an alias for a complex command which utilizes many flags.</p>
      <p>An example is as follows:</p>

      <SyntaxHighlighter language='bash' style={dracula}>
        {"# We want to give this frequently used command a shorter name, since we don't want to write -Al all the time.\nls -Al"}
      </SyntaxHighlighter>

      <p>
        The <code>-Al</code> part of the command is called <b>flags</b>. They provide additional functionalities or change the behavior of the commands.<br />
        In this case, the <code>-A</code> flag is used to show hidden files and <code>-l</code> is for list view.<br />
        Note that <code>-Al</code> is a combination of two flags and it can be represented as <code>-A -l</code> as well.
      </p>
      <p>
        On a side note, you can learn about the flags of any command using <code>man</code>.<br />
        For the <code>ls</code> example, we have:
      </p>

      <SyntaxHighlighter language='bash' style={dracula}>
        {"# Usage: man <command_name>\nman ls"}
      </SyntaxHighlighter>

      <p>
        Getting back to aliases, let's say you want to run <code>ls -Al</code> whenever you type <code>ll</code>.<br />
        You can do this as follows:
      </p>

      <SyntaxHighlighter language='bash' style={dracula}>
        {"# Beware of spaces\nalias ll='ls -Al'\n# And then, you can run\nll"}
      </SyntaxHighlighter>

      <p><b>Congratulations!</b> You have created your first alias. However, if you close your terminal, the alias will be deleted. We will get back to this later on.</p>

      <p>Most popular distributions already provide you with some QoL improving aliases. You can list all of them by running <code>alias -p</code></p>

      <p>Before moving on to the next topic, I will provide you with some of my aliases to hopefully give you inspiration to create your own aliases.</p>

      <SyntaxHighlighter language='bash' style={dracula}>
        {"alias ls='ls --color=auto'\nalias ll='ls -Al'\nalias cls='clear'\nalias ..='cd ..'\nalias grep='grep -n --color=auto'    # Colored and line number grep\nalias ip='ip --color=auto'\nalias diff='diff --color=auto'\nalias tmux='tmux -f $HOME/.config/Scripts/tmux.conf'    # You can also specify config paths by using aliases"}
      </SyntaxHighlighter>

      <br />

      <Divider />

      <h2 id='functions'>Functions</h2>

      <p>
        Functions are similar to aliases, where you can, for instance, create a function called <code>ll</code> and inside of it call <code>ls -Al</code> and achieve the same result as the previous alias example. However, there is more to functions.<br />
        With functions, you can have parameters or inputs and execute more complex things compared to aliases. Think of it as any other programming language.
      </p>

      <p>
        To write out first function, we first need to create a file and edit it. You can use <code>touch {'<'}file_name{'>'}</code> to create an empty file and edit it with your editor of choice. The file's extension is not important.<br />
        For this example, I will create a file called 'functions'.
      </p>

      <SyntaxHighlighter language='bash' style={dracula}>
        {"touch functions"}
      </SyntaxHighlighter>

      <SyntaxHighlighter language='bash' style={dracula}>
        {"# Editing the 'functions' file\n\n#!/bin/bash\nfunction first_function() {\n    echo I got $# parameters.\n    echo First parameter is $1.\n}"}
      </SyntaxHighlighter>

      <p>
        This function will print out the number of parameters it received, as well as the first parameter.<br />
        The <code>#!/bin/bash</code> line is a special line called "shebang". It is defined at the beginning of a script file and tells the system to use '/bin/bash' to execute the script.
      </p>

      <p>
        After saving the file, must first source it. You can do it by <code>source {'<'}file_name{'>'}</code>.<br />
        Taken from the <code>man</code> page, this command lets us "Execute commands from a file in the current shell".
      </p>

      <SyntaxHighlighter language='bash' style={dracula}>
        {"source functions\n\nfirst_function Hello World!    # Calling our function with parameters 'Hello' and 'World!'\n\n# Output\nI got 2 parameters.\nFirst parameter is Hello."}
      </SyntaxHighlighter>

      <p>To print every input parameter, we can utilize loops as follows:</p>

      <SyntaxHighlighter language='bash' style={dracula}>
        {`# Editing the 'functions' file

#!/bin/bash
function first_function() {
    echo I got $# parameters.
    for i in $@
    do
        printf "$i "  # Prints elements without new lines at the end
    done
    echo  # New line just for prettier output
}`}
      </SyntaxHighlighter>

      <p>Since we changed our function, we have to source our 'functions' file and then call our function.</p>

      <SyntaxHighlighter language='bash' style={dracula}>
        {`source functions
first_function Hello World!

# Output
I got 2 parameters.
Hello World!`}
      </SyntaxHighlighter>

      <p>We could have just used <code>echo $@</code> to achieve the same thing, however, I wanted to show you the bash loops.</p>

      <p>
        <b>Great!</b> We wrote our first function that takes arguments. Now some bash related info, mainly the <b>special variables</b>.<br />
        There are many special variables, here are some to get you started:
      </p>

      <SyntaxHighlighter language='bash' style={dracula}>
        {`$0: This variable holds the name of the script itself, including the path, if provided.
$1, $2, $3, ...: These variables represent the command-line arguments passed to the script. $1 holds the first argument, $2 holds the second, and so on.
$#: This variable stores the number of command-line arguments passed to the script.
$@: It represents all the command-line arguments as a list. This is similar to using "$1", "$2", etc., but allows you to reference all arguments collectively.
$?: After a command is executed, this variable contains the exit status of the last executed command. A value of 0 typically indicates success, while non-zero values indicate errors.
$$: This variable contains the process ID (PID) of the current script or shell.`}
      </SyntaxHighlighter>

      <p>
        Now, I will provide some of my favorite functions to hopefully inspire you to make your own!
      </p>

      <SyntaxHighlighter language='bash' style={dracula}>
        {`# Creates a directory and if the creation is successful, navigates to the newly created directory
function mkdircd() {
    mkdir -p "$1" && cd "$1"
}

# fzf + vim. Performs a fzf search for a file and opens it with vim
vif() {
    if [[ $# -gt 0 && $1 == '-h' ]]; then
        file=$(find . 2> /dev/null | fzf)
    else
        file=$(fzf)
    fi
    if [[ $? != 0 ]]; then
        return 0
    else
        vim $file
    fi
}
`}
    </SyntaxHighlighter>
    <br />

    <Divider />

    <h2 id='persistency'>Persistency</h2>

    <p>
      We learned about aliases and functions. However, when we close our terminal, our aliases go away and for the functions, we need to source them every time.<br />
      This is clearly not efficient.<br />
      A common method to solve this problem is to create a file called <code>aliasesrc</code> (you can name the file whatever you want. This name is somewhat the norm). "rc" stands for "Run Commands". Again "rc" is the norm and "rc" files are often used to customize the behavior and appearance of software and the user's shell environment.<br />
      So, we will stick to the conventions.<br />
      After creating your <code>aliasesrc</code> file, you can put all your aliases and functions inside of it.<br />
      You can place this file where ever you want. I tend to place it inside of <code>.config</code> directory, which is in your <code>$HOME</code> directory.
      After you placed the file, it is time to modify your <code>.bashrc</code> file to source our "rc" file when we open the terminal. (Note: Depending on the distro, you might have .zshrc or something other than .bashrc)
    </p>

    <SyntaxHighlighter language='bash' style={dracula}>
      {`# Inside your .bashrc add the following line
source $HOME/.config/aliasesrc

# Alternatively, you can use ~ instead of $HOME
# In that case, add this
source ~/.config/aliasesrc`}
    </SyntaxHighlighter>

    <p>And we are done! Now, every time you open your terminal, your aliases and functions will be ready to run!</p>

    <p>Thanks for reading!</p>

    </div>
  )
}