function Tools() {
  return (
    <>
      <h2>My GitHub Projects/Tools</h2>
      <p>
        More projects to come! <br />
        Currently working on few cool projects, but you know how personal projects go... :D <br />
        <a href='https://github.com/ArmanHZ'>My GitHub link.</a>
      </p>
      <b>Note: I've provided a brief description for the projects here. Each project has their own detailed descriptions in their respective Git repositories.</b>


      <div className='tools-div'>
        <b>CTF Write-ups</b>
        <p>Write-ups from some of the CTFs that I participated</p>
        <p>GitHub Link: <a href='https://github.com/ArmanHZ/ctf-writeups'>CTF Write-ups</a></p>
        <p>CTF Time Link: <a href='https://ctftime.org/user/128528'>CTF Time Profile</a></p>
      </div>


      <div className='tools-div'>
        <b>Go Detect That Hash! (gdth)</b>
        <p>A hash detection tool written in Go. Group project with <a href='https://openpunk.com/'>Cpunk!</a></p>
        <p>GitHub Link: <a href='https://github.com/ArmanHZ/go-detect-that-hash'>Go Detect That Hash</a></p>
        <p>Check out Cpunk's blog for our interesting findings on <a href='https://openpunk.com/journal/go-detect-that-hash/'>Go Regex Limitations</a> while working on this project!</p>
      </div>
      <div className='tools-div'>
        <b>Go Port Scanner (gps)</b>
        <p>This tools is your portable pocket nmap and is intended to be used in environments where nmap is not available. For instance, you are pivoting through a jump box and you are already utilizing porxychains and you have to perform a scan from the 3rd box, which also happens to be a Windows machine. Good luck getting your nmap through.</p>
        <p>GitHub Link: <a href='https://github.com/ArmanHZ/go-port-scanner'>Go Port Scanner</a></p>
      </div>
      <div className='tools-div'>
        <b>Simple PHP Webshell</b>
        <p>This is when you have a PHP upload/RCE and you want something stable and nice to look at when you run your commands and ultimately get a reverse shell.</p>
        <p>GitHub Link: <a href='https://github.com/ArmanHZ/Simple-PHP-Webshell'>Simple-PHP-Webshell</a></p>
      </div>
      <div className='tools-div'>
        <b>Run-As-Powershell</b>
        <p>
          I wrote this script after I encountered the problem with using the cmd.exe version of Runas. <br />
          The cmd.exe version spawns a new cmd window and runs the command there. This is ok when you are in a GUI environment, but it is useless when you have a reverse shell on the victim. <br />
          This script does not spawn a new session and will run the command and show its output on your current shell session. <br />
          There are two versions. Clean and Normal. <br />
          Normal version contains a powershell reverse shell one liner and <b>WILL</b> trigger the Windows defender on normal machines.
        </p>
        <p>GitHub Link: <a href='https://github.com/ArmanHZ/Run-As-Powershell'>Run-As-Powershell</a></p>
      </div>
      <div className='tools-div'>
        <b>My Linux Dotfiles</b>
        <p>
          Config files and tools I use for my Pentesting Linux VM. <br />
          <i>I use Arch btw.</i>
        </p>
        <p>GitHub Link: <a href='https://github.com/ArmanHZ/Linux_configs'>Linux_configs</a></p>
      </div>
    </>
  )
}

export default Tools;
