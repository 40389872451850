import React from 'react';
import PersistentDrawerLeft from './components/MainContentDisplay';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import HackTheBox from './components/HackTheBox';
import Guide from './components/Guide';
import Tools from './components/Tools';
import NotFoundPage from './components/NotFoundPage';
import RedPanda from './htb/RedPanda';
import Support from './htb/Support';
import StreamIO from './htb/StreamIO';
import Scrambled from './htb/Scrambled';
import Timelapse from './htb/Timelapse';
import Devzat from './htb/Devzat';
import DistroIntro from './components/guides/DistroIntro';
import BashIntro from './components/guides/BashIntro';
import HacktheboxIntro from './components/guides/HacktheboxIntro';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <Router>
      <Routes>
        <Route path='/' element={ <PersistentDrawerLeft headerText='Welcome to my Hacking blog!' content={ <Home /> } /> } />
        <Route path='/htb' element={ <PersistentDrawerLeft headerText='HackTheBox Write-ups' content={ <HackTheBox /> } /> } />
        <Route path='/tools' element={ <PersistentDrawerLeft headerText='My Tools' content={ <Tools /> } /> } />
        <Route path='/guide' element={ <PersistentDrawerLeft headerText='Guides' content={ <Guide /> } /> } />
        {/*Writeups*/}
        <Route path='/htb/redpanda' element={ <PersistentDrawerLeft headerText='RedPanda' content={ <RedPanda /> } /> } />
        <Route path='/htb/support' element={ <PersistentDrawerLeft headerText='Support' content={ <Support /> } /> } />
        <Route path='/htb/streamio' element={ <PersistentDrawerLeft headerText='StreamIO' content={ <StreamIO /> } /> } />
        <Route path='/htb/scrambled' element={ <PersistentDrawerLeft headerText='Scrambled' content={ <Scrambled /> } /> } />
        <Route path='/htb/timelapse' element={ <PersistentDrawerLeft headerText='Timelapse' content={ <Timelapse /> } /> } />
        <Route path='/htb/devzat' element={ <PersistentDrawerLeft headerText='Devzat' content={ <Devzat /> } /> } />
        {/*Writeups*/}
        {/*Guides*/}
        <Route path='/guide/hacktheboxintro' element={ <PersistentDrawerLeft headerText='Hack The Box Labs Guide' content={ <HacktheboxIntro /> } /> } />
        <Route path='/guide/bashintro' element={ <PersistentDrawerLeft headerText='Squash the Beef with Bash!' content={ <BashIntro /> } /> } />
        <Route path='/guide/distrointro' element={ <PersistentDrawerLeft headerText='Distro Intro' content={ <DistroIntro /> } /> } />
        {/*Guides*/}
        <Route path='*' element= { <PersistentDrawerLeft headerText='404 Not Found' content={ <NotFoundPage /> } /> }/>
      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;
