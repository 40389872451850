import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

function createData(
  name: string,
  difficulty: string,
  solvedOn: string,
  writeup: string
) {
  return { name, difficulty, solvedOn, writeup };
}

const rows = [
  createData('RedPanda', 'Easy', '09/04/2022', 'redpanda'),
  createData('Support', 'Easy', '08/29/2022', 'support'),
  createData('StreamIO', 'Medium', '08/13/2022', 'streamio'),
  createData('Scrambled', 'Medium', '07/29/2022', 'scrambled'),
  createData('Timelapse', 'Easy', '05/31/2022', 'timelapse'),
  createData('Devzat', 'Medium', '01/15/2022', 'devzat'),
];

function HackTheBox() {
  const navigate = useNavigate();
  return (
    <>
      <p>CTF Write-ups are <i><a href='https://github.com/ArmanHZ/ctf-writeups'>Here!</a></i></p>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="left">Difficulty</TableCell>
              <TableCell align="left">Solved on</TableCell>
              <TableCell align="left">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">{row.difficulty}</TableCell>
                <TableCell align="left">{row.solvedOn}</TableCell>
                <TableCell align="left">{ row.writeup === '' ? <Button style={{textTransform: 'none'}} variant='contained' disabled>Active Box</Button> : <Button style={{textTransform: 'none'}} color='secondary' variant='contained' onClick={() => navigate('/htb/' + row.writeup)} >Write-up</Button> }</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default HackTheBox;
