import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';

function Guide() {
  return (
    <>
      <h2>Here are my Guides/Tutorials for various topics.</h2>
      <p>Click on the title links to read the corresponding guides!</p>


      <div className='tools-div'>
        <b><Link to='/guide/hacktheboxintro'>Hack The Box Labs Intro Guide</Link></b>
        <p>Beginner's guide on how to launch lab machines, connect to vpn and start hacking with HackTheBox.</p>
        <p>09/17/2023</p>
      </div>

      <div className='tools-div'>
        <b><Link to='/guide/bashintro'>Squash the Beef with Bash!</Link></b>
        <p>Beginner Bash tutorial going over creating aliases and custom functions and more.</p>
        <p>09/15/2023</p>
      </div>

      <div className='tools-div'>
        <b><Link to='/guide/distrointro'>Distribution and VM tips for pen-testing</Link></b>
        <p>A guide to help you choose a GNU/Linux distro and set it up as a Virtual Machine and start your journey!</p>
      </div>
    </>
  )
}

export default Guide;
